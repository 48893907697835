import React from "react";
import g from "glamorous";
import Layout from "../components/layout";
import { css } from "glamor";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Container } from "glamorous-grid";
import Masonry from "react-masonry-component";
import { Link } from "gatsby";
import { GoQuote } from "react-icons/go";
import { FaHome } from "react-icons/fa";

const Wrapper = g.article({
  maxWidth: `1250px`,
  margin: `0 auto`,
  paddingBottom: `4em`,
});

const Title = g.h1({
  textAlign: `center`,
  fontSize: `32px`,
  color: `#3c4d68`,
  margin: `2em auto 1em`,
  fontVariant: `none`,
  fontWeight: `600`,
  letterSpacing: `.01em`,
  lineHeight: `44px`,
  "@media(max-width: 767px)": {
    fontSize: `23px`,
    LineHeight: `31px`,
  },
});

const Li = g.li({
  width: `33%`,
  margin: `1em 0 1em`,

  "@media(max-width: 992px)": {
    width: `50%`,
  },
  "@media(max-width: 771px)": {
    width: `100%`,
  },
});

const Tile = g.section({
  backgroundColor: `white`,
  padding: `0.4em 1em 1em`,
  width: `85%`,
  margin: `0 auto`,
  borderRadius: `4px`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
});

const Testimonial = g.p({});

const Credit = g.div({
  textAlign: `right`,
  fontSize: `12px`,
});

const cssQuote = css({
  fontSize: `54px`,
  color: `#3c4d68`,
  display: `block`,
});

const masonryOptions = {
  transitionDuration: 0,
};

const Breadcrumb = g.ul({
  fontSize: `13px`,
  lineHeight: `20px`,
  marginLeft: `0&nbsp;!important`,
  marginTop: `0`,
  marginBottom: `10px&nbsp;!important`,
  fontWeight: `500`,
  letterSpacing: `.015em`,
});

const BreadcrumbItem = g.li({
  display: `inline`,
  "&:first-of-type": {
    "&::before": {
      content: `none`,
    },
  },
  "&::before": {
    content: `/`,
    padding: `0 .5em`,
  },
});

const cssBreadcrumbLink = css({
  color: `#3c4d68`,
  borderBottom: `none`,
});

const imagesLoadedOptions = { background: ".my-bg-image-el" };

export default () => (
  <Layout>
    <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
      <Helmet>
        <title>Témoignages et avis des patients | Dr Cédric Kron</title>
        <meta
          name="description"
          content="Les patients du Dr Cédric Kron font par de leurs témoignages et de leur retours d'expérience après des opérations de chirurgie esthétique ou des traitements de médecine esthétique."
        />
        <html lang="fr" />
        <link
          rel="alternate"
          hreflang="en"
          href="https://www.dr-kron.com/en/testimonials/"
        />
        <script type="application/ld+json">{` {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Témoignages et avis des patients",
        "item": "https://www.dr-kron.com/temoignages-avis-patients/"
        }]
            }
  `}</script>
      </Helmet>
      <Menu current="temoignages" pathEn="/en/testimonials/" />

      <Wrapper>
        <Container css={{ padding: `0 15px&nbsp;!important` }}>
          <Title>Témoignages et avis des patients</Title>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                <FaHome
                  css={{
                    fontSize: `1.2em`,
                    paddingBottom: `.2em`,
                    transform: `translateY(5px)`,
                  }}
                />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Témoignages et avis des patients</BreadcrumbItem>
          </Breadcrumb>

          <Masonry
            className={"my-gallery-class"} // default ''
            elementType={"ul"} // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            imagesLoadedOptions={imagesLoadedOptions} // default {}
            css={{ listStyleType: `none`, margin: `0` }}
          >
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Cela faisait déjà quelques années que je réfléchissais à une
                  méthode naturelle et compatible avec mon traitement médical.
                  L'injection d'acide hyaluronique n'étant pas possible pour moi
                  j'ai alors découvert la lipostructure des lèvres grâce au site
                  du Dr&nbsp;Kron.
                  <br />
                  Je me suis lancée et pris RDV avec le docteur Kron. Quelqu'un
                  d'attentif, à l'écoute et rassurant. L'intervention passée,
                  c'était une nouvelle vie qui commençait. Depuis février, j'ai
                  confiance en moi, je suis épanouie et transformée&nbsp;! Un
                  résultat satisfaisant et très naturel.
                  <br />
                  Si c'était à refaire, je le referais les yeux fermés et
                  d'ailleurs je pense le refaire d'ici quelques années, avec le
                  Dr Kron bien sûr&nbsp;!
                  <br />
                  Je tiens à remercier le Docteur Kron et toute son équipe pour
                  leur professionnalisme et leur disponibilité.
                </Testimonial>
                <Credit>Chloé Lammelin</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Très satisfaite d'une opération de chirurgie esthétique. Le
                  résultat était à la hauteur de mes attentes et j'ai été mise
                  en confiance dès la première consultation.
                </Testimonial>
                <Credit>Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Je remercie le Docteur Kron, pour son travail, sa sincérité,
                  son écoute et sa gentillesse. Pour m'avoir trouvé des rdv :
                  anesthésiste et mammographie, si rapidement. Je suis très
                  satisfaite du résultat et les cicatrices sont très très
                  belles, très fines et discrètes, c’est magnifique&nbsp;! Bravo
                  et merci&nbsp;!! Merci aussi aux assistants du Docteur Kron à
                  la clinique et à vous les secrétaires du cabinet du Dr Kron,
                  pour votre disponibilité et votre gentillesse.
                </Testimonial>
                <Credit>Séverine | Augmentation mammaire</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Je me suis fais opérer d’une rhinoplastie il y quelques
                  semaines par le Dr Kron. Cela faisait plusieurs années que ça
                  me traversait l’esprit, depuis l’adolescence pour être honnête
                  mais j’avais peur de franchir le pas. Peur que ce soit raté,
                  peur que ce ne soit pas naturel, peur que cela change mon
                  visage ... J’ai même eu plusieurs consultations avec d’autres
                  chirurgiens, fixant des dates d’opération, et déclinant
                  finalement la fameuse date finale. Le jour où j’ai rencontré
                  le Dr Kron, j’ai tout de suite su que je n’avais plus
                  d’hésitations à avoir. J’ai fixé une date, le Dr Kron a été
                  très attentif à mes attentes (diminution de la bosse sans
                  toucher au reste) et ses assistantes ont été adorables et très
                  agréables à côtoyer. Le jour J, je n’ai pas vraiment réalisé,
                  mais lorsqu’il m’a enlevé le pansement quelques jours plus
                  tard, j’ai été immensément heureuse du résultat&nbsp;: naturel
                  et aucun changement hormis la diminution de la bosse que
                  j’avais. Je souhaite à quiconque ayant ce complexe
                  insupportable de faire confiance au Dr Kron qui a changé ma
                  vie&nbsp;!
                </Testimonial>
                <Credit>Rhinoplastie | Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Outre son écoute attentive et son extrême délicatesse, docteur
                  Kron vous met naturellement en confiance. Les soins prodigués
                  mettent en exergue toutes ses qualités professionnelles. Merci
                  à vous et votre équipe.
                </Testimonial>
                <Credit>Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Très satisfaite des résultats, et surtout une écoute
                  merveilleuse, merci Dr&nbsp;Kron de votre professionnalisme,
                  je souris chaque matin grâce à vous.
                </Testimonial>
                <Credit>Lifting LVPA | Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Je voulais vous remercier très sincèrement du travail que vous
                  avez accompli. Je n'avais jamais eu le courage de me lancer
                  dans ce type d'opération qui me faisait peur, car je voulais
                  avant tout «&nbsp;assumer&nbsp;» mon complexe en passant par
                  l'acceptation de moi-même plutôt que la chirurgie. Pourtant,
                  cette opération a été bénéfique pour mon bien-être personnel,
                  et je ne me regarde désormais plus de la même façon. Les
                  choses sont devenues beaucoup plus simples, notamment lorsque
                  je suis entourée de plusieurs personnes&nbsp;: je ne me
                  torture plus l'esprit à penser perpétuellement à mon nez. Je
                  suis extrêmement heureuse du résultat. Vous avez su écouter
                  très précisément ce que j'imaginais et ce que je
                  voulais&nbsp;: un résultat naturel qui ne pouvait que sublimer
                  le nez que j'aurais toujours dû avoir, à mon goût.
                </Testimonial>
                <Credit>M. N. | Rhinoplastie</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Mon opération s’est très bien déroulée. Je suis super contente
                  du résultat. Mes sincères remerciements encore au Dr Kron et
                  aussi à toute l’équipe qui a été super patiente avec moi (à
                  cause de mon organisation). Vous êtes une Superbe Équipe et le
                  Dr Kron est Top ! The Maestro 👍🙏
                </Testimonial>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Patiente depuis plusieurs années, toujours très satisfaite des
                  résultats. Je recommande fortement le Dr&nbsp;Kron pour son
                  professionnalisme et son sérieux. Très à l'écoute pour
                  satisfaire la demande.
                </Testimonial>
                <Credit>Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Reconstruction du ventre (abdoplastie) : Le dr Kron a fait un
                  travail d'orfèvre. La cicatrice est presque invisible.
                  Professionnel et bienveillant qui prends en considération le
                  psychique et le psychologique. Grand chirurgien.
                </Testimonial>
                <Credit>Caroline P | Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Lorsque j'ai appelé le cabinet, on m'a prévenu que j'aurais 6
                  petits mois à attendre pour avoir un rendez vous mais cela ne
                  m'a pas freiné. Au contraire, si un chirurgien est autant
                  demandé, c'est qu'il doit etre de qualité, non&nbsp;? Cela
                  fait désormais un peu moins de deux mois que j'ai été opérée :
                  un lipo des mollets et des chevilles. J'en suis ravie. Je mets
                  désormais des bottes, ce que je n'ai jamais pu faire de ma
                  vie. Le docteur Kron est bienveillant et à l'écoute. Je n'ai
                  jamais eu peur, aucune angoisse vis à vis de mon opération car
                  il sait etre rassurant, répondre à toutes vos questions et
                  rester disponible si besoin. Allez y les yeux fermés&nbsp;!
                </Testimonial>
                <Credit>Liposuccion | Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Dr Kron est un magicien. J'ai fait un lifting LVPA, le
                  résultat est extraordinaire. J'avais tellement peur de toucher
                  à mon visage, mais je ne supportais plus de me voir dans le
                  miroir à cause de mes bajoues et mon double menton. Dr Kron a
                  su me rassurer et a fait un travail d'orfèvre. Je n'ai pas
                  ressenti de douleur et les suites post-opératoires étaient
                  quasi inexistantes. Il est entouré de personnes qui lui
                  ressemblent Aussi bien son aide opératoire que les secrétaires
                  et assistantes sont très humaines, rassurantes, aimables et
                  compétentes. Je suis très satisfaite de résultat - tout le
                  monde me trouve rajeunit sans se rendre compte que j'ai fait
                  un lifting - c'est impressionnant. Le résultat est très
                  naturel- j'ai retrouvé mon visage d'avant. Merci encore Dr
                  Kron - vous êtes le meilleur !
                </Testimonial>
                <Credit>Lifting du visage | Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Opérée l'été dernier, je suis très satisfaite du résultat de
                  l'intervention et de la façon dont tout s'est déroulé. Le Dr
                  Kron a su m'écouter, répondre à mes questions et bien
                  m'expliquer l'opération. Merci également à Rihame et Laura qui
                  étaient adorables.
                </Testimonial>
                <Credit>
                  Chirurgie esthétique | Avis publié sur Google Maps
                </Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Chirurgien à l'écoute, répond à toutes nos questions, nous met
                  en confiance dès la première consultation, ses explications
                  sont claires, nous conseille dans notre choix, grand
                  professionnalisme. Très bon suivi. Satisfaite du résultat,
                  plus de complexe. Je recommande le Dr Kron sans hésitation.
                </Testimonial>
                <Credit>Pauline | Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  J'ai eu la chance de bénéficier de l'expertise du Docteur Kron
                  pour un double lifting complet du visage. Le résultat est
                  stupéfiant : présentable au bout de 3&nbsp;jours sans problème
                  à mon travail, cicatrices cachées et invisibles un mois plus
                  tard, le résultat s'est encore magnifié par la suite.
                </Testimonial>
                <Credit>Marie | Lifting du visage</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Aujourd’hui, rendez-vous au cabinet du Docteur Kron. Au
                  départs beaucoup d’appréhension, première fois pour moi, mais
                  tellement entendu du bien que je me suis lancée, je suis ravie
                  comme tout&nbsp;: accueil au top, médecin très à l’écoute et
                  très humain, chose rare de nos jours chez un médecin, et
                  secrétaires adorables aussi, merci beaucoup à toute l’équipe
                  du @docteurkron pour votre bienveillance.
                </Testimonial>
                <Credit>Nassima. B | Avis publié sur Instagram</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  J'ai mis plusieurs années avant de prendre la réelle décision
                  de passer à l'acte et de me faire opérer. Mais quand je suis
                  tombée sur le Dr&nbsp;Kron je n'ai plus douté une seule
                  seconde. Je voulais me faire opérer par le meilleur et j'ai
                  trouvé le meilleur. Un chirurgien à l'écoute et qui conseil
                  vraiment en fonction de la morphologie de sa patiente. Son but
                  est d'avoir des résultats naturels et c'est ce que j'ai eu la
                  chance d'avoir. Une poitrine parfaite&nbsp;! Belle&nbsp;! Et
                  très naturelle&nbsp;! Et en plus de ça je n'ai eu aucune
                  douleur après l'opération&nbsp;! Sans parler du suivi qui
                  était vraiment top avec des assistantes au top&nbsp;! Toujours
                  là pour répondre à n'importe quelle question. Je vous conseil
                  vraiment d'aller le rencontrer, où que vous soyez&nbsp;! Je
                  suis de Marseille mais le déplacement n'est rien quand on voit
                  le professionnalisme de ce chirurgien.
                </Testimonial>
                <Credit>Marina M. | Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Et bien moi je connais bien le docteur Kron. Avant lui un
                  autre chirurgien esthétique m'a ravagé le visage avec un
                  lifting loupé et de mauvais conseils, j'étais bien trop jeune
                  pour qu'on touche à mon visage (40 ans)..... J'ai du me
                  supporter des années avant de pouvoir y remédier. Lorsqu'il
                  fut temps de retoucher mon visage, le docteur Kron m'a fait un
                  lifting qui bien que j'ai 54&nbsp;ans me fait frais et je me
                  sens bien dans ma peau....J'ai aussi pris du ventre (l'âge) et
                  bien il l'a totalement supprimé par une lipo super efficace.
                  Moi qui craignait la douleur j'ai quasiment rien senti après
                  et pas une marque sur le ventre. Si des gens sont déçus par
                  lui, j'en suis fort surpris, j'ai une totale confiance en lui,
                  au moins avec ses interventions ses conseils je suis heureux
                  et me sens bien, c'est le plus important... Ah oui, après la
                  lipo il m'a conseillé le sport. A son cabinet il y à un coach
                  sportif fabuleux avec un appareil fantastique qui en 20
                  minutes et de l'assiduité vous redonne un corps agréable, ca
                  devrait être pris en charge par la sécu. Je ne me supportais
                  plus en maillot de bain, surtout à cause du regard des autres,
                  après quelques semaines de sport (2x par semaine) j'ai passé
                  un été au bord de la plage formidable... Je sais que je
                  vieilli, personne n'y peut rien, mais au moins avec le docteur
                  Kron ca va moins vite.
                </Testimonial>
                <Credit>Philippe C. | Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>Bonjour à tous.</p>

                  <p>
                    Dr&nbsp;Kron m'a opéré le 11/09/2018, j'ai subi une
                    abdominoplastie, mammaire et une lipoaspiration le même
                    jour. Comment vous dire&nbsp;: mise à part ses réelles
                    compétences, c'est un homme extraordinaire. Il a su dès le
                    1er RDV me trouver les mots et me convaincre avec des cas
                    concrets que l'opération allait me changer la vie. Il ne
                    vous vendra pas du rêve et si c'est pas possible il vous le
                    dira. Aujourd'hui cela fait 4&nbsp;mois et je peux vous dire
                    que suis une autre. J'ai repris confiance à moi et suis très
                    heureuse de ma nouvelle vie (physique). Il me suit
                    régulièrement depuis l'opération et ce jusqu'au 1&nbsp;an
                    post-opératoire.
                  </p>

                  <p>
                    N'hésiter vraiment pas à vous rapprocher de lui rien qu'une
                    fois, vous comprendrez ce que j'ai pu ressentir et vivre.
                    Vous savez, de nos jours il est très important de tomber sur
                    des personnes de confiances (ce qui est de plus en plus rare
                    et même au niveau médical...) : Dr&nbsp;Kron, je ne sais si
                    vous me lirez, mais je tenais à vous remercier
                    personnellement. Sachez que je ne vous oublierai jamais. Je
                    n'ai pas de tatouage sur mon corps mais considère l'être
                    aujourd'hui grâce à la magie de vos 2&nbsp;mains...
                  </p>
                </Testimonial>
                <Credit>
                  Mme Ch... R. | Abdominoplastie et lipoaspiration
                </Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />

                  <p>
                    Vous pouvez aller chez le docteur Kron les yeux fermés.
                    Extrêmement professionnel, il réalise ses opérations avec
                    beaucoup de naturel et avec beaucoup de bienveillance. Je
                    n'ai ressenti aucune douleur suite à ma mammoplastie qui de
                    surcroit est très bien exécutée et très naturelle. Je savais
                    qu'il faisait partie des meilleurs chirurgiens de Paris,
                    j'en suis désormais convaincue. Le résultat est magnifique.
                    Merci encore docteur.
                  </p>
                </Testimonial>
                <Credit>Julie S.| Avis publié sur Google Maps</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Comme dit le proverbe : un sourire ne coûte rien mais il crée
                  beaucoup. C'est avec le corps et le sourire qu'on communique
                  davantage qu'avec les mots, car on est d'abord vu avant d'être
                  entendu. Alors confier son visage, quand on l'a décidé, à un
                  praticien attentif, expert et rigoureux est le meilleur
                  chemin. Tous mes remerciements sincères au Dr&nbsp;Kron.
                </Testimonial>
                <Credit>
                  Eric Casanova et ses années... | Chirurgie esthétique
                </Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>Cher Docteur,</p>

                  <p>
                    Je n'ai pas osé vous retenir ce matin après le rdv mais je
                    voulais vous remercier pour votre travail sur mes seins.
                  </p>

                  <p>
                    Ce que vous avez fait me plaît vraiment énormément, c'est
                    très très beau. Je suis très heureuse et émerveillée à la
                    fois car le résultat est bien au-delà de mes attentes, je
                    n'imaginais pas à quel point ça serait joli.
                  </p>

                  <p>
                    Je voulais vous dire aussi que j'ai beaucoup aimé le premier
                    rdv de consultation. Je me suis sentie vraiment écoutée,
                    respectée et absolument pas jugée, ça a été très important
                    pour moi.
                  </p>

                  <p>
                    Je vous remercie vraiment beaucoup pour votre présence
                    réconfortante et votre gentillesse avant l'opération.
                    J'avais peur, mais j'ai été rassurée quand vous êtes venu me
                    voir dans ma chambre et avez fait les marques au feutre sur
                    mes seins.
                  </p>

                  <p>
                    Je vous remercie aussi pour votre douceur. Je pensais que
                    j'aurais des bleus partout après l'opération car j'ai la
                    peau fragile et au final, je n'en ai quasiment pas eu et mes
                    cicatrices sont toutes fines. Je suis vraiment émerveillée
                    par ce que vous avez fait.
                  </p>

                  <p>Voilà, je voulais vous le dire.</p>

                  <p>Merci des millions de fois,</p>
                </Testimonial>
                <Credit>Mlle L. | Augmentation mammaire</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>Cher Docteur,</p>

                  <p>
                    Pour la seconde fois, vous avez opéré mon épouse avec
                    succès, démontrant ainsi, que vous n’avez pas usurpé d’une
                    part, votre « Médaille d’or et de Chirurgie » et d’autre
                    part, votre « Prix de l’Académie Nationale de Chirurgie.
                  </p>

                  <p>
                    Je tiens tout particulièrement à vous féliciter, car vous
                    honorez votre Profession en transformant l’image de vos
                    patients de manière très positive.
                  </p>

                  <p>
                    Ayant eu l’honneur et le plaisir de vous rencontrer, j’ai
                    constaté que malgré la qualité de vos titres nombreux, vous
                    êtes resté un Chirurgien très discret et très humain.
                  </p>
                </Testimonial>
                <Credit>Lifting du visage</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  J'ai subi une augmentation mammaire en août 2012&nbsp;et je
                  suis très satisfaite du résultat. Mes seins sont magnifiques,
                  la cicatrice ne se voit quasiment pas. Le résultat est
                  naturel. Je n'ai eu aucune douleur suite à l'opération. Encore
                  merci au Docteur Kron.
                </Testimonial>
                <Credit>Augmentation mammaire | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Le Dr&nbsp;Kron m'a posé des fils Silhouette Soft pour
                    retendre l'ovale.
                  </p>

                  <p>Le résultat est super.</p>

                  <p>
                    Je conseille ce médecin, qui essaie de se conformer au
                    souhait du patient, ce qui est rare car en général, le
                    médecin esthétique fait, ce que lui trouve esthétique... et
                    quelquefois, c'est juste moche.
                  </p>
                </Testimonial>
                <Credit>Fils tenseurs | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Chirurgien à l'écoute, rassurant, qui donne des explications
                  complètes sur la façon dont il va procéder. Il va m'opérer
                  dans 3&nbsp;semaines, j'ai toute confiance en lui.
                </Testimonial>
                <Credit>Chirurgie esthétique | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Apres avoir consulté un certain nombre de chirurgiens, mon
                    choix se portera finalement sur le Docteur Kron rencontré en
                    août 2013&nbsp;(une amie me l'avait conseillé). Qualité
                    d'écoute, douceur, pédagogue dans ses explications plutôt
                    que vendeur... On se sent détendu, en confiance. Avant même
                    de m'opérer, il a su me faire rêver... Hâte de poser mon
                    rendez-vous que j attends depuis presque un an maintenant.
                  </p>

                  <p>
                    N.B : J'ai trouvé la première visite très complète, il vous
                    remet votre petite clé USB avec votre profil 3D avant/après,
                    avec votre véritable profil je précise&nbsp;!
                  </p>
                </Testimonial>
                <Credit>
                  Marie | Avis publié sur le site Multiesthetique.fr
                </Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Suite à l’opération reconstructive par auto greffe pratiquée
                  en juin 2013, nous avons autorisé l’utilisation des divers
                  documents et photographies dans le cadre d’information
                  générale relative aux causes accidentelles, en remerciant
                  particulièrement M. Cédric Kron pour l’écoute, le
                  professionnalisme au service de personnes meurtries, dont il a
                  soulagé la disgrâce et les peines.
                </Testimonial>
                <Credit>Chirurgie réparatrice | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Excellent chirurgien, il est top et très professionnel. J’ai
                  eu une chirurgie mammaire en 2017 et tout s’est bien déroulée,
                  mise à part que j’ai mis du temps à cicatriser à cause de mon
                  problème de coagulation sanguine... Docteur Kron a très vite
                  réagit et s’est très bien occupé de moi. Merci docteur 🙏
                </Testimonial>
                <Credit>Sonia M.G. | Google Maps</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Mon rdv s'est très bien passé, il conseille très bien et vous
                  met très en confiance, très satisfaite de ce premier rdv.
                </Testimonial>
                <Credit>Premier Rendez-vous | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  J'ai subi, en mars 2013, une augmentation mammaire par le Dr
                  Kron. J'ai imaginé cette opération pendant tellement d'années,
                  que j'étais très anxieuse du résultat. Et ce résultat est
                  au-delà de mes espérances. Le Dr&nbsp;Kron a su m'écouter, me
                  rassurer. Il a fait un travail remarquable, avec tant de
                  naturel. Je me sens enfin épanouie, bien dans ma peau et dans
                  mon corps. Un grand merci au Dr&nbsp;Kron.
                </Testimonial>
                <Credit>Aurélie A. | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Le Docteur Kron, outre ses compétences techniques de haut vol,
                  porte un vrai regard sur ses patients. C'est sa vision quasi
                  "artistique" qui m'a poussée à passer entre ses mains et ai
                  donc retrouvé une poitrine de 20 ans avec des suites
                  opératoires faciles (pas un bleu!) et une reprise de mes
                  activités très rapide.
                </Testimonial>
                <Credit>Rajeunissement mammaire | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Suite au botox anti-migraine que j'ai pratique au mois de
                  fevrier, je tenais à vous dire merci. Mes migraines ont
                  considerablement diminuées. J'ai attendu plusieurs semaines
                  avant d'être sûre que cela faisait effet au soleil. Merci
                  encore. À renouveler&nbsp;!
                </Testimonial>
                <Credit>Traitement de la migraine | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Je n'ai qu'un seul regret, ne pas l'avoir fait plus tôt&nbsp;!
                  Les résultats de cette opération sont au delà de mes
                  espérances. J'ai longtemps complexé sur mes jambes que je
                  n'osais pas montrer car très épaisses, lourdes et grasses.
                  J'avais une silhouette type "Orangina" (...) Jamais je
                  n'aurais imaginé pouvoir avoir cette morphologie là, moi...
                </Testimonial>
                <Credit>Lifting des cuisse | Estheticon.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Je tiens à remercier sincèrement le Dr&nbsp;Kron pour la «
                    magique » reconstruction mammaire qu'il a effectué suite à
                    la mastectomie que j'avais subi pour ma guérison.
                  </p>

                  <p>
                    Je suis très satisfaite du résultat esthétique et je ne
                    m’attendais pas à un aussi bon résultat. La symétrie est
                    parfaite et je n’ai pas eu de douleurs.
                  </p>

                  <p>
                    Je revis une nouvelle vie en ayant retrouvé un nouveau sein
                    et en n’ayant plus à retirer et remettre quotidiennement la
                    prothèse externe que je portais.
                  </p>

                  <p>
                    Je remercie particulièrement l’équipe qui m’a accueilli et
                    l’attention que vous portez à vos patients.
                  </p>

                  <p>Merci beaucoup de m’avoir aidé à refermer cette page.</p>
                </Testimonial>
                <Credit>Mariam | Reconstruction mammaire</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Mr Kron, je vous remercie pour tout. Votre lieu d’accueil est
                  formidable vous aviez raison. Merci. Merci. Merci.
                </Testimonial>
                <Credit>Lifting ovale, blépharoplastie</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Un grand merci à toute l’équipe surtout au Docteur Kron. Vous
                  avez été extraordinaire et merci encore pour votre attention.{" "}
                </Testimonial>
                <Credit>Catherine | Lipoaspiration</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Suite au botox anti-migraine que j'ai pratique au mois de
                  février, je tenais à vous dire merci. Mes migraines ont
                  considérablement diminuées. J'ai attendu plusieurs semaines
                  avant d'être sûre que cela faisait effet au soleil. Merci
                  encore. À renouveler&nbsp;!
                </Testimonial>
                <Credit>Injection de botox | Migraine</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Bravo Dr&nbsp;Kron&nbsp;! Merci pour tout et pour cette
                  chirurgie qui redonne du baume au cœur aux femmes de la
                  soixantaine.
                </Testimonial>
                <Credit>Sandrine | Chirurgie esthétique</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Le Docteur KRON a effectué un travail que l’on peut déjà
                    qualifier de remarquable.
                  </p>

                  <p>
                    Merci, car cela compte infiniment pour moi. Je vous en suis
                    très reconnaissante.
                  </p>

                  <p>
                    Un grand merci à tout le personnel dont la gentillesse et le
                    professionnalisme sont remarquables à tout point de vue.
                  </p>
                </Testimonial>
                <Credit>Jacqueline | Lifting Ovale Visage</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Un très grand merci au Dr&nbsp;Kron dont l’excellente
                  réputation s’étend des plages de l’île de Ré à
                  Bruxelles&nbsp;! Un très grand merci également au Dr Michon et
                  toute l’équipe. Avec toute mon infinie reconnaissance.
                </Testimonial>
                <Credit>Nathalie | Lipoaspiration</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Merci beaucoup au Dr&nbsp;Kron, vous êtes un artiste.
                </Testimonial>
                <Credit>Ewelina | Prothèses mammaire</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  J'ai subi, en mars 2013, une augmentation mammaire par le Dr
                  Kron. J'ai imaginé cette opération pendant tellement d'années,
                  que j'étais très anxieuse du résultat. Et ce résultat est
                  au-delà de mes espérances. Le Dr&nbsp;Kron a su m'écouter, me
                  rassurer. Il a fait un travail remarquable, avec tant de
                  naturel. Je me sens enfin épanouie, bien dans ma peau et dans
                  mon corps. Un grand merci au Dr&nbsp;Kron.
                </Testimonial>
                <Credit>Aurélie A. | Augmentation mammaire</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  J'ai subi une augmentation mammaire en août 2012&nbsp;et je
                  suis très satisfaite du résultat. Mes seins sont magnifiques,
                  la cicatrice ne se voit quasiment pas. Le résultat est
                  naturel. Je n'ai eu aucune douleur suite à l'opération. Encore
                  merci au Docteur Kron.
                </Testimonial>
                <Credit>Augmentation mammaire</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Je tenais à vous remercier pour mon opération et sa réussite.
                  Vous ne m'avez pas seulement permis d'avoir le corps que je
                  souhaitais mais aussi toutes les répercussions positives que
                  cela a pu avoir sur ma vie que j'essaye de construire...
                  Ainsi, un grand merci à tout ce que vous avez fait pour moi.{" "}
                </Testimonial>
                <Credit>Laura | Chirurgie des seins</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Cela fait déjà presque 4&nbsp;ans que je connais le Docteur
                    Kron. Je lui ai demandé une réduction mammaire et, par la
                    suite, une lipostructure et j'en suis très satisfaite.
                    Depuis, je me sens mieux dans mon corps et ne complexe plus
                    comme avant,en plus de cela, mes cicatrices sont hyper
                    discrètes.
                  </p>

                  <p>
                    Le Dr&nbsp;Kron a été très attentif à mes désidératas,
                    disponible, accueillant et très sérieux dans son travail et
                    dans le suivi post-opératoire qu'il m'a apporté.
                  </p>
                  <Credit>Flora | Réduction mammaire</Credit>
                </Testimonial>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Le Docteur Kron, outre ses compétences techniques de haut
                    vol, porte un vrai regard sur ses patients. C'est sa vision
                    quasi-artistique qui m'a poussée à passer entre ses mains et
                    ai donc retrouvé une poitrine de 20 ans avec des suites
                    opératoires faciles (pas un bleu!) et une reprise de mes
                    activités très rapide.
                  </p>
                  <Credit>Augmentation mammaire</Credit>
                </Testimonial>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Dr&nbsp;Kron merci pour ma nouvelle vie qui commence… Merci
                    pour votre gentillesse et votre écoute. Merci à tout le
                    service du 4ème.
                  </p>
                  <Credit>Eve | Plastie Mammaire bilatérale</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Le Dr&nbsp;Kron m'a opéré il y a quelques temps, il m'a posé
                    des implants fessiers et cela s'est très bien passé.
                    J'appréhendais les suites opératoires car j'avais peur
                    d'avoir mal et, au final, c'était largement supportable car
                    il est très pointilleux et très précis dans ses techniques
                    opératoires.
                  </p>

                  <p>
                    Aujourd'hui, je suis vraiment contente du résultat : mes
                    fesses ont plus de volume et sont bien rebondies. J'ai
                    complètement confiance en ce chirurgien, c'est quelqu'un de
                    très professionnel, il a toujours été à mon écoute, est
                    resté fidèle à mes attentes et je l'en remercie sincèrement.
                  </p>
                  <Credit>Plastie des fesses</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Compétence incomparable, humanisme profond.. Un mélange rare
                  et admirable. Merci au Dr&nbsp;Kron et son équipe.
                  <Credit>Stéphanie</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Dr&nbsp;Kron, merci pour vos compétences, vos qualités
                  d’écoute, votre disponibilité, en un mot votre
                  professionnalisme. Merci.
                  <Credit>Michele</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Merci à toute l’équipe et à Marie, au Dr&nbsp;Kron grand
                  professionnel et sachant donner toute confiance justifiée et à
                  toute l’équipe.
                  <Credit>Angélique</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Merci infiniment à tout le personnel de la clinique, notamment
                  au Dr&nbsp;Kron très attentionné et agréable. Bonne
                  continuation
                  <Credit>Emmanuelle</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Merci Dr&nbsp;Kron de votre savoir faire de vos doigts de
                  magicien, et de votre douceur. Merci aussi à Marie pour les
                  bons conseils.
                  <Credit>Estelle</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Cher Docteur, je me sens si légère et si heureuse&nbsp;! Merci
                  de me rendre belle et de la confiance que vous m’avez inspirée
                  depuis notre premier rendez-vous.
                  <Credit>Marie</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Équipe fantastique&nbsp;! Merci pour tout le réconfort&nbsp;!
                  Je pars d’ici avec un grand sourire le cœur léger&nbsp;!
                  Mention spéciale à Marie pour son incroyable énergie&nbsp;!
                  Dr&nbsp;Kron, je me sens resculptée&nbsp;! Un grand Merci pour
                  ce travail "d’Artisan" tout à fait exceptionnel&nbsp;!
                  <Credit>Clotilde</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Je suis heureuse du résultat et heureuse de vous avoir fait
                  confiance: j'ai l'impression d'avoir les yeux que j'ai
                  toujours voulu avoir et je me sens si bien. Les cicatrices
                  s'estompent rapidement et je n'ai eu que des compliments. Je
                  ne sais comment vous dire merci...
                  <Credit>Edith | Lifting des sourcils</Credit>
                </Testimonial>
              </Tile>
            </Li>
          </Masonry>
        </Container>
      </Wrapper>

      <Footer />
    </g.Div>
  </Layout>
);
